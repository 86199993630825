// Variables
$navbar-default-bg: rgba(0,0,0, 0.6);
$brand-primary: #840d16;
$navbar-default-link-color: #fcaf17;
$navbar-default-link-active-color: #f9c970;
$navbar-default-link-hover-color: #f9c970;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-bg: transparent;
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-color: #840d16;

// "bootstrap-sprockets" must be imported before "bootstrap" and "bootstrap/variables"
@import "bootstrap/bootstrap-sprockets";
@import "bootstrap/bootstrap";

$header-height: 118px;
$route-colour: #fcaf17;

.navbar-top{
  padding: 25px 0;
  min-height: $header-height;
  a{
    font-size: 16px;
    text-transform: uppercase;
  }
}
.navbar-brand{
  padding: 0 8px;
  height: 62px;
}
.container{
  max-width: 990px;
}
html, body{
  height: 100%;
}
body.inner{
  padding-top: $header-height;
}
footer{
  background: #fff;
  height: 116px;
  padding: 34px 0;
}
.main-heading{
  font-size: 30px;
  color: $route-colour;
}
.inner footer{
  border-top: 2px solid $route-colour;
}
.home .navbar-right {
  display: none;
}
.sprites {
  background: url('images/sprites.png') no-repeat;
  display: inline-block;
}
.extra{
  width: 44px;
  height: 44px;
  margin: 0 9px;
}
.content-area{
  min-height: calc(100% - #{$header-height});
}
.styled-infowindow{
  div:first-child > div:last-child,
  div > div > div > div{
    background: $brand-primary !important;
  }
  background: $brand-primary !important;
  font-size: 14px !important;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
}
.ex-fb {
  background-position: 0 -45px;
  &:hover {
    background-position: 0 0;
  }
}
.ex-tw {
  background-position: 0 -135px;
  &:hover {
    background-position: 0 -90px;
  }
}
.ex-yt {
  background-position: 0 -225px;
  &:hover {
    background-position: 0 -180px;
  }
}

#map {
  width: 100%;
  height: 100%;
  background: #a4a4a4;
  .infowindow{
    background: #840d16;
    font-size:24px;
    width: 250px;
    color: #fff;
  }
}
.location-content{
  line-height: 2em;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .home footer{
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .col-images{
    padding-right: 0;
    & + div .location-content{
      margin-left: 35px;
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {

}
